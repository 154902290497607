$('#contacts_submit').click(function(e) {
    e.preventDefault();

    //Validazione
    var fd = new FormData($('#contacts_form')[0]);

    $.ajax({
        url: site_url + '/contacts/form_info/store',
        type: "POST",
        dataType: 'json',
        data: fd,
        contentType: false,
        processData: false,
        success: function (response) {
            if (response.statusCode === 200) {
                if (!response.validation) {
                    $('#contacts_errors').html(response.template);
                } else {
                    $('#contacts_form').hide();
                    $('#contacts_form_success').show();

                    // Scroll
                    $('html,body').animate({scrollTop: $("#contacts_form_success").offset().top - 100},'slow');
                }
            }
        },
        error: function (response, status, error) {
            console.log('Si &egrave; verificato un errore! Riprova');
        }
    });
});
